import { FunctionComponent } from "react";
import { Button } from "../ui/button";
import { Link as RouterLink } from "react-router-dom";

import logo from "../../assets/svg/light-horizontal.svg";

const NotFound: FunctionComponent = () => {
  return (
    <div className="relative bg-white w-full overflow-hidden flex flex-col items-center justify-center py-44 md:py-32 px-20 sm:px-15 xs:px-8 text-center text-5xl text-slate-300 font-text-xl-font-normal px-20 box-border text-center text-109xl text-slate-800 font-text-lg-font-normal">
      <div className="self-stretch flex flex-col items-center justify-start">
        <div className="self-stretch flex flex-col items-center justify-start gap-10">
          <div className="self-stretch flex flex-col items-center justify-start">
            <div className="self-stretch relative leading-[150%] font-extrabold text-9xl">
              404
            </div>
            <div className="self-stretch relative text-4xl leading-[150%] font-extrabold text-slate-900">
              Stránka sa nenašla
            </div>
          </div>
          <div className="self-stretch flex flex-col items-center justify-start gap-[24px] text-lg text-slate-500">
            <div className="self-stretch relative leading-[150%]">
              Stránka, ktorú hľadáte, neexistuje. Skontrolujte prosím URL adresu
              alebo sa vráťte na domovskú stránku.
            </div>
          </div>
          <RouterLink to="/">
            <Button
              variant="default"
              size="default"
              className="uppercase"
              onClick={() => window.scrollTo(0, 0)}
            >
              Späť na domovskú stránku
            </Button>
          </RouterLink>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
