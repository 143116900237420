import * as React from "react";
import { cn } from "src/lib/utils";
import CloseIcon from "../../assets/custom_icons/CloseIcon";
import InfoIcon from "../../assets/custom_icons/InfoIcon";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  validationRule?: RegExp;
  label?: string;
  optional?: boolean;
  helpText?: string;
  warningText?: string;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  checkEachCharacter?: boolean;
  onValueChange?: (value: string) => void;
  isVerified?: boolean; // Add this line
  centered?: boolean; // Add this line
  showCloseIcon?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type,
      validationRule,
      label,
      optional,
      helpText,
      warningText,
      Icon,
      checkEachCharacter,
      onValueChange,
      isVerified = true,
      centered = false,
      showCloseIcon = true,
      ...props
    },
    ref
  ) => {
    const [value, setValue] = React.useState("");
    const [isValid, setIsValid] = React.useState(true);
    const [showTooltip, setShowTooltip] = React.useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
      if (checkEachCharacter && validationRule) {
        setIsValid(validationRule.test(event.target.value));
      }
      if (onValueChange) {
        onValueChange(event.target.value);
      }
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      if (!checkEachCharacter && validationRule && !optional) {
        const isValid = validationRule.test(event.target.value);
        setIsValid(isValid);
      }
    };

    const handleClear = () => {
      setValue("");
      setIsValid(true);
    };

    return (
      <div className="flex flex-col gap-2">
        {label && (
          <label
            className={cn(
              "text-left text-md font-semibold",
              props.disabled ? "text-gray-400" : "text-slate-800"
            )}
          >
            {label}
            {optional && (
              <span className="text-slate-400 font-medium"> - Voliteľné</span>
            )}
            {helpText && (
              <span
                className="inline-block ml-1 relative top-1"
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <InfoIcon className="h-5 w-5 sm:h-6 sm:w-6" color="#64748b" />
                {showTooltip && (
                  <div className="absolute self-stretch top-0 left-5 sm:top-7 sm:left-auto sm:right-0 font-normal bg-slate-700 text-white text-xs py-2 px-3 rounded-md w-fit z-50 min-w-[200px]">
                    {helpText}
                  </div>
                )}
              </span>
            )}
          </label>
        )}
        <div className="relative flex flex-wrap">
          {Icon && (
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Icon className="h-5 w-5" color="#64748b" aria-hidden="true" />
            </div>
          )}
          <input
            onChange={handleChange}
            type={type}
            value={value}
            onBlur={handleBlur}
            className={cn(
              "input-hide-arrows", // Add this line
              "flex h-full w-full rounded-md border text-slate-800 border-slate-200 bg-slate-50 px-3 py-2.5 text-sm disabled:text-slate-400",
              "ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-normal",
              "placeholder:text-muted-foreground focus-visible:ring-offset-fuchsia-500 focus-visible:outline-none",
              "focus-visible:ring-0 focus-visible:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50",
              !Icon && "pl-3", // Add this line
              Icon && "pl-10", // Modify this line
              (!isValid || !isVerified) &&
                "border-red-500 bg-red-50 text-red-700",
              isValid && "text-slate-800 border-slate-200 bg-slate-50",
              centered && "text-center", // Add this line
              !value && !isVerified && "border-red-500 bg-red-50 text-red-700",
              className
            )}
            ref={ref}
            {...props}
          />
          {value && showCloseIcon && (
            <div
              onClick={handleClear}
              className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
            >
              <CloseIcon className="h-5 w-5" color="#64748b" />
            </div>
          )}
        </div>
        {!isValid && warningText && (
          <div className="w-full overflow-auto text-red-600 mt-2 whitespace-normal text-sm">
            {warningText}
          </div>
        )}
      </div>
    );
  }
);

Input.displayName = "Input";

export { Input };
