import { FunctionComponent, useEffect, useState } from "react";
import { Button } from "../ui/button";
import badges from "../../assets/png/nexprim_badges_small.png";
import dtf from "../../assets/png/nexprim_dtf_small.png";
import transfer from "../../assets/png/screenprint_hero.png";
import logo from "../../assets/svg/light-horizontal.svg";
import bgImageDefault from "../../assets/png/Nexprim.com-desktop.png";
import bgImageLg from "../../assets/png/Nexprim.com-lg.png";
import bgImageMd from "../../assets/png/Nexprim.com-md.png";
import bgImageSm from "../../assets/png/Nexprim.com-sm.png";
import bgImageXs from "../../assets/png/Nexprim.com-xs.png";
import Cookies from "js-cookie";
import { useTranslation } from "../../TranslationContext";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/custom/select_language";

import { ReactComponent as SK } from "../../assets/svg/SK.svg";
import { ReactComponent as CZ } from "../../assets/svg/CZ.svg";
import { ReactComponent as DE } from "../../assets/svg/DE.svg";
import { ReactComponent as EU } from "../../assets/svg/EU.svg";

const LandingPage: FunctionComponent = () => {
  const translations = useTranslation();
  const [bgImage, setBgImage] = useState(bgImageDefault);

  const getDefaultLanguage = () => {
    const browserLang = navigator.language.slice(0, 2).toUpperCase();
    const supportedLangs = ["EN", "CZ", "DE", "SK"];
    return supportedLangs.includes(browserLang) ? browserLang : "EN";
  };

  const [selectedLanguage, setSelectedLanguage] = useState(
    Cookies.get("lang") || getDefaultLanguage()
  );

  useEffect(() => {
    const currentLang = Cookies.get("lang");
    if (currentLang !== selectedLanguage) {
      Cookies.set("lang", selectedLanguage, { expires: 365 });
      window.location.href = window.location.pathname; // Redirect to the same page
    }
  }, [selectedLanguage]);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 640) {
        setBgImage(bgImageXs);
      } else if (width < 768) {
        setBgImage(bgImageSm);
      } else if (width < 1024) {
        setBgImage(bgImageMd);
      } else if (width < 1280) {
        setBgImage(bgImageLg);
      } else {
        setBgImage(bgImageDefault);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // call the function initially

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getIcon = (language: string) => {
    switch (language) {
      case "EN":
        return <EU className="w-8 h-auto" />;
      case "CZ":
        return <CZ className="w-8 h-auto" />;
      case "DE":
        return <DE className="w-8 h-auto" />;
      case "SK":
        return <SK className="w-8 h-auto" />;
      default:
        return <SK className="w-8 h-auto" />;
    }
  };

  const getLanguageLabel = (language: string) => {
    switch (language) {
      case "EN":
        return "English";
      case "CZ":
        return "Čeština";
      case "DE":
        return "Deutsch";
      case "SK":
        return "Slovenčina";
      default:
        return "Slovenčina";
    }
  };

  return (
    <div
      style={{ backgroundImage: `url(${bgImage})` }}
      className="bg-cover bg-center font-outfit relative w-full min-h-screen bg-slate-950 flex flex-col items-center justify-center text-center text-5xl text-slate-300 font-text-xl-font-normal gap-10 md:gap-10"
    >
      <div className="flex flex-col items-center justify-center gap-4 pt-10">
        <img
          src={logo}
          alt="Logo"
          className="w-4/6 overflow-hidden max-h-full"
        />
        <div className="flex flex-col items-center justify-center">
          <div className="relative text-xl leading-[150%] text-slate-300 sm:text-base">
            {translations.landingPage.header}
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-row relative items-center justify-center z-1000">
        <div
          className="relative"
          onClick={(e) => e.stopPropagation()} // Stop event propagation at the wrapper level
          style={{ zIndex: 1000 }} // Ensure the wrapper has a high z-index
        >
          <Select
            name="language"
            autoComplete="language"
            defaultValue={selectedLanguage}
            onValueChange={setSelectedLanguage}
          >
            <SelectTrigger className="flex items-center justify-between bg-gray-800 text-white rounded-lg px-4 py-2 shadow-lg border-0 w-full">
              <div className="flex items-center space-x-2">
                {getIcon(selectedLanguage)}
                <span className="font-medium pr-2 sm:hidden">
                  {getLanguageLabel(selectedLanguage)}
                </span>
              </div>
            </SelectTrigger>
            <SelectContent
              className="bg-gray-900 text-white rounded-lg shadow-lg border-0 w-full"
              style={{ pointerEvents: "auto" }} // Ensure pointer events are enabled
            >
              <SelectGroup>
                <SelectItem
                  value="SK"
                  className="w-full flex items-center justify-start px-4 py-2 hover:bg-gray-700 rounded-lg"
                >
                  <SK className="w-8 h-auto mr-2 " />{" "}
                  <span className="inline sm:hidden">Slovenčina</span>
                </SelectItem>
                <SelectItem
                  value="CZ"
                  className="w-full flex items-center justify-start px-4 py-2 hover:bg-gray-700 rounded-lg"
                >
                  <CZ className="w-8 h-auto mr-2" />{" "}
                  <span className="inline sm:hidden">Čeština</span>
                </SelectItem>
                <SelectItem
                  value="EN"
                  className="w-full flex items-center justify-start px-4 py-2 hover:bg-gray-700 rounded-lg"
                >
                  <EU className="w-8 h-auto mr-2" />{" "}
                  <span className="inline sm:hidden">English</span>
                </SelectItem>
                <SelectItem
                  value="DE"
                  className="w-full flex items-center justify-start px-4 py-2 hover:bg-gray-700 rounded-lg"
                >
                  <DE className="w-8 h-auto mr-2" />{" "}
                  <span className="inline sm:hidden">Deutsch</span>
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </div>
      <div className="flex flex-col w-full max-w-[1080px]">
        <div className="flex flex-row gap-12 xl:gap-6 lg:flex-col md:gap-10 w-full items-center justify-evenly text-left text-base text-white lg:pb-10 sm:px-6">
          <div className="rounded-2xl bg-slate-900 w-full max-w-[340px] h-[475px] sm:max-h-[420px] overflow-hidden shrink-0 flex flex-col items-center justify-between py-10 sm:py-6 px-6 box-border gap-6 md:gap-4">
            <div className="relative leading-[150%] font-medium text-xl text-gradient md:text-lg sm:text-base">
              {translations.landingPage.cardOneHeader}
            </div>
            <div className="max-w-[230px] flex flex-col items-center justify-center py-1 px-0 box-border">
              <img
                src={badges}
                alt="Nexprim Odznaky"
                className="self-stretch h-full"
              />
            </div>
            <div className="self-stretch flex flex-col items-center justify-end gap-[32px] text-center text-xl">
              <div className="self-stretch flex flex-col items-center justify-end gap-[8px]">
                <div className="self-stretch relative leading-[130%] font-semibold text-xl sm:text-lg">
                  {translations.landingPage.cardOneTitle}
                </div>
                <div className="self-stretch relative text-mini font-light text-base leading-[130%] sm:text-sm">
                  {translations.landingPage.cardOneDescription}
                </div>
              </div>
              <a
                href={translations.landingPage.cardOneLink}
                target="_blank"
                rel="noopener noreferrer"
                className="w-[200px]"
              >
                <Button variant="default" size="default">
                  {translations.landingPage.cardOneButton}
                </Button>
              </a>
            </div>
          </div>
          <div className="rounded-2xl bg-slate-900 w-full max-w-[340px] sm:max-h-[450px] overflow-hidden shrink-0 flex flex-col items-center justify-start py-10 sm:py-6 px-6 box-border gap-[13px] md:gap-4">
            <div className="relative leading-[150%] font-medium text-xl text-slate-500 md:text-lg sm:text-base">
              {translations.landingPage.cardTwoHeader}
            </div>
            <div className="max-w-[200px] flex flex-col items-center justify-center py-1 px-0 box-border">
              <img
                src={transfer}
                alt="Nexprim transfer"
                className="self-stretch h-full"
              />
            </div>
            <div className="self-stretch flex flex-col items-center justify-end gap-[32px] text-center text-xl">
              <div className="self-stretch flex flex-col items-center justify-end gap-[1px]">
                <div className="self-stretch relative leading-[130%] font-semibold text-xl sm:text-lg">
                  {translations.landingPage.cardTwoTitleOne}
                </div>
                <div className="self-stretch relative leading-[130%] font-semibold text-xl sm:text-lg mb-1">
                  {translations.landingPage.cardTwoTitleTwo}
                </div>
                <div className="self-stretch relative text-mini font-light text-base leading-[130%] sm:text-sm">
                  {translations.landingPage.cardTwoDescription}
                </div>
              </div>
              <a
                href={translations.landingPage.cardTwoLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="default" className="w-[200px]" size="default">
                  {translations.landingPage.cardTwoButton}
                </Button>
              </a>
            </div>
          </div>
          <div className="rounded-2xl bg-slate-900 w-full max-w-[340px] h-[475px] sm:max-h-[420px] overflow-hidden shrink-0 flex flex-col items-center justify-between py-10 sm:py-6 px-6 box-border gap-6 md:gap-4">
            <div className="relative leading-[150%] font-medium text-xl text-gradient md:text-lg sm:text-base">
              {translations.landingPage.cardThreeHeader}
            </div>
            <div className="max-w-[230px] h-[173px] flex flex-col items-center justify-center py-1 px-0 box-border">
              <img
                src={dtf}
                alt="Nexprim DTF"
                className="self-stretch h-full"
              />
            </div>
            <div className="self-stretch flex flex-col items-center justify-end gap-[32px] text-center text-xl">
              <div className="self-stretch flex flex-col items-center justify-end gap-[8px]">
                <div className="self-stretch relative leading-[130%] font-semibold text-xl sm:text-lg">
                  {translations.landingPage.cardThreeTitle}
                </div>
                <div className="self-stretch relative text-mini font-light text-base leading-[130%] sm:text-sm">
                  {translations.landingPage.cardThreeDescription}
                </div>
              </div>
              <a
                href={translations.landingPage.cardThreeLink}
                target="_blank"
                rel="noopener noreferrer"
                className="w-[200px]"
              >
                <Button variant="default" size="default">
                  {translations.landingPage.cardThreeButton}
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
